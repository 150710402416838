import React, {lazy, Suspense} from 'react';
import './App.css';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {BrowserRouter as Router} from 'react-router-dom';
import Typography from "@material-ui/core/Typography";
import { Helmet } from 'react-helmet';
import Disclaimer from './components/Disclaimer/Disclaimer';

const Search = lazy(() => import('./containers/Search/Search'));


const theme = createMuiTheme({
  typography: {
      useNextVariants: true,
      fontFamily: "newsGothic_demi,Tahoma,Arial,Verdana,Helvetica,sans-serif",
      fontSize: 22,
      lineSpacing: 14,
  },
  palette: {
      primary: {
          main: '#009'
      },
      secondary: {
          main: '#669'
      }
  },
});

/*
Props vs State:
                                                props   state
Can get initial value from parent Component?    Yes     Yes
Can be changed by parent Component?             Yes     No
Can set default values inside Component?*       Yes     Yes
Can change inside Component?                    No      Yes
Can set initial value for child Components?     Yes     Yes
Can change in child Components?                 Yes     No

*/


function App() {

  return (
    <MuiThemeProvider theme={theme}>
    <div className="App">
      <Helmet>
           <title>{"Deutsche Börse Market Data + Services - MiFID II View"}</title>
      </Helmet>
      <header className="App-header">
         <Suspense fallback={
                <Typography variant="subtitle1" gutterBottom>
                    Loading frame ...
                </Typography>}>
                  <Router>
                      <Search />
                      <Disclaimer />
                  </Router>
          </Suspense>
      </header>
    </div>
    </MuiThemeProvider>
  );
}

export default App;

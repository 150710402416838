import React, { useState, useEffect} from 'react'
import Dialog from "@material-ui/core/Dialog";
import Fade from "@material-ui/core/Fade";


const styles = {
    root: {
        position: 'absolute',
        zIndex: 100,
        top: '0%',
        background: '#ffffff',
        height: '100%',
        overflow: 'auto',
        textAlign: 'left',
        fontFamily: 'newsGothic_light, Tahoma, Arial, Verdana, Helvetica, sans-serif'
    },
    header: {
        backgroundColor: '#00008f',
        minHeight: 34,
        fontSize: '1.8rem',
        fontFamily: 'newsGothic_demi,Tahoma,Arial,Verdana,Helvetica,sans-serif',
        padding: '9px 0 0 40px',
        color: '#fff',
        marginBottom: 16
    },
    agreeBtn: {
        width: 150,
        marginRight: 20,
        color: '#000',
        backgroundColor: '#e2e2e2',
        border: 0
    }
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

// eslint-disable-next-line no-empty-pattern
const Disclaimer = ({} = this.props) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(true)
    }, [])

    const handleClickBtn = () => {
        setShow(false)
    }

    const handleBackBtn = () => {
        window.location.href = "https://www.mds.deutsche-boerse.com/mds-en";
    }

    return (
        <Dialog fullScreen open={show} TransitionComponent={Transition}>
        <div style={{...styles.root, display: (show) ? 'block' : 'none'}}>
            <div style={{...styles.header}}>
                Terms of Use
            </div>
            <div className="row">
                <div id="col1" className="col">
                    <div className="product content">
                        <p>Deutsche Börse AG ("<b>DBAG</b>") makes available market data - subject to Art. 13(1) of Regulation (EU) No. 600/2014
                        ("<b>MiFIR</b>") - 15 minutes after its initial creation by<br/>
                        Frankfurt Stock Exchange (Frankfurter Wertpapierbörse "<b>FWB</b>")
                        or Eurex Deutschland ("<b>Delayed Data</b>") free of charge subject to the terms of these Terms of Use.<br/>
                        <br/>
                        <b>I. Licence Grant</b>
                        <br/><br/>
                        1.	Subject to paragraph 2 below, DBAG grants the user who is accessing and using Delayed Data (“<b>Delayed Data User</b>”)<br/>
                        a non-exclusive, non-transferable and non-sublicensable right with respect to the Delayed Data free of charge.</p>
                        <br/>
                        2.	Any access to and use of the Delayed Data is subject to a licence which is only granted free of charge if the Delayed Data<br/>
                        User does not commercialize the Delayed Data. In particular, a commercialization of Delayed Data takes place in the following events<br/>
                        (and a licence fee applies):<br/>
                        <div className="introduction rte-text">
                            <div>
                                <ul className="rte--list">
                                    <li>Delayed Data User onward disseminates Delayed Data to third parties for a fee 
                                        (including a general fee for accessing its services);
                                    </li>
                                    <li>Delayed Data User creates and offers value-added services using Delayed Data 
                                        which are then sold for a fee to third parties, trading venues, APAs and CTPs.
                                    </li>
                                </ul>
                            </div>
                        </div>
                                        
                        The Delayed Data User acknowledges and accepts that in the event a commercialization takes place a licence subject<br/>
                        to the Market Data Dissemination Agreement is required and any usage without a licence in place is strictly prohibited. 
                        <br/>
                        <br/>
                        <b>II. Liability Limitation, No Representation</b>
                        <br/><br/>
						DBAG or, in general, Deutsche Börse Group do not:
                        <div className="introduction rte-text">
                            <div>
                                <ul className="rte--list">
                                    <li>Provide any financial advice or to make recommendations to invest in
 										financial instruments or any other kinds of securities.
                                    </li>
                                    <li>Assume any responsibility or liability for products or services offered
										by third parties which are based on Delayed Data.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="product content">
                        DBAG and Deutsche Börse Group give no warranty, and exclude any liability (whether in
                        negligence or otherwise), in connection with the
                        provision of Delayed Data. <br/>DBAG does not assume any contractual relationship with the
                        purchasers of products or services offered by
                        third parties which are based on Delayed Data.
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="product content">
                        <p>Specifically, DBAG and Deutsche Börse Group do not give any warranty, express or implied, and
                            exclude, in particular, any liability about:</p>
                        <div className="introduction rte-text">
                            <div>
                                <ul className="rte--list">
                                    <li>The accuracy, timeliness, and completeness of Delayed Data.</li>
                                    <li>The merchantability and the fitness for a particular purpose or use of
                                        Delayed Data
                                    </li>
                                    <li>Products or services offered by third parties which may be based on
                                        Delayed Data.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="product content">
                        DBAG and Deutsche Börse Group give no warranty and exclude any liability, for any errors,
                        omissions or interruptions in publishing of the Delayed Data.
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="product content">
                        Under no circumstances will DBAG or Deutsche Börse Group be liable (whether in negligence or
                        otherwise) for any lost profits or indirect,
                        punitive, special or consequential damages or losses, <br/>arising as a result of such errors,
                        omissions or interruptions in publishing of the
                        Delayed Data or generally in relation to products or services which may be based on Delayed
                        Data, <br/>even in circumstances where DBAG or
                        Deutsche Börse Group are aware that such loss or damage may occur.
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <div className="product content">
                        By clicking on the "I agree" button below, the User acknowledges the terms stipulated in
                        these Terms of Use.
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col col-2">
                    <div className="product content">
                        <button id="agreeBtn" style={{...styles.agreeBtn}} onClick={handleClickBtn}>I agree</button>
                    </div>
                </div>
                <div className="col">
                    <div className="product content">
                        <button id="backBtn" style={{...styles.agreeBtn}} onClick={handleBackBtn}>Back</button>
                    </div>
                </div>
            </div>
        </div>
        </Dialog>
    )
}

export default Disclaimer
